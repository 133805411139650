import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { request } from '../../services/intercom';
import {
  IntercomAdmin,
  IntercomArticle,
  IntercomCollection,
  IntercomSection,
} from '../../types/intercom';
import { ListQuery } from '../../types/request';
import { ListResult } from '../../types/response';
import { defaultReactQueryParams } from '../../utils/request';
import { QueryParams } from '../global/useApi';

type UseIntercomFAQCollectionProps = ListQuery & {};
export function useIntercomFAQCollections<R = IntercomCollection>(
  { page, ...restListQueryParams }: UseIntercomFAQCollectionProps,
  reactQueryParams: Partial<QueryParams> = {},
) {
  const identifiers = ['intercomFAQCollections'];

  if (page) {
    identifiers.push(`${page}`);
  }

  async function api({
    signal,
  }: QueryFunctionContext): Promise<AxiosResponse<ListResult<R>>> {
    return new Promise(async (resolve, reject) => {
      try {
        const result: AxiosResponse<ListResult<R>> = await request.request({
          url: '/api/intercom-auth0/faq-helpcenter-collections/',
          signal,
          params: { ...restListQueryParams },
        });
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }

  return useQuery(identifiers, api, {
    ...defaultReactQueryParams,
    ...reactQueryParams,
  });
}

type UseIntercomFAQAdminsProps = ListQuery & {};
export function useIntercomFAQAdmins<R = IntercomAdmin>({
  page,
  ...restListQueryParams
}: UseIntercomFAQAdminsProps) {
  const identifiers = ['intercomFAQAdmins'];

  if (page) {
    identifiers.push(`${page}`);
  }

  async function api({
    signal,
  }: QueryFunctionContext): Promise<AxiosResponse<ListResult<R>>> {
    return new Promise(async (resolve, reject) => {
      try {
        const result: AxiosResponse<ListResult<R>> = await request.request({
          url: '/api/intercom-auth0/admins/',
          signal,
          params: { ...restListQueryParams },
        });
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }

  return useQuery(identifiers, api, {
    ...defaultReactQueryParams,
  });
}

type UseIntercomFAQSectionProps = ListQuery & {
  parent_id?: string;
};
export function useIntercomFAQSections<R = IntercomSection>(
  { page, ...restListQueryParams }: UseIntercomFAQSectionProps,
  reactQueryParams: Partial<QueryParams> = {},
) {
  const identifiers = ['intercomFAQSections'];

  if (page) {
    identifiers.push(`${page}`);
  }
  if (restListQueryParams.parent_id) {
    identifiers.push(restListQueryParams.parent_id);
  }

  async function api({
    signal,
  }: QueryFunctionContext): Promise<AxiosResponse<ListResult<R>>> {
    return new Promise(async (resolve, reject) => {
      try {
        const result: AxiosResponse<ListResult<R>> = await request.request({
          url: '/api/intercom-auth0/faq-helpcenter-sections/',
          signal,
          params: {
            ...restListQueryParams,
          },
        });
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }

  return useQuery(identifiers, api, {
    ...defaultReactQueryParams,
    ...reactQueryParams,
  });
}

type UseIntercomFAQArticlesInCollectionProps = ListQuery & {
  collectionId: string;
};
export function useIntercomFAQArticlesInCollection<R = IntercomArticle>(
  {
    page,
    collectionId,
    ...restListQueryParams
  }: UseIntercomFAQArticlesInCollectionProps,
  reactQueryParams: Partial<QueryParams> = {},
) {
  const identifiers = ['intercomFAQArticlesInCollection'];

  if (page) {
    identifiers.push(`${page}`);
  }
  if (collectionId) {
    identifiers.push(collectionId);
  }

  async function api({
    signal,
  }: QueryFunctionContext): Promise<AxiosResponse<ListResult<R>>> {
    return new Promise(async (resolve, reject) => {
      try {
        const result: AxiosResponse<ListResult<R>> = await request.request({
          url: `/api/intercom-auth0/faq-helpcenter-collections/${collectionId}/articles/`,
          signal,
          params: {
            ...restListQueryParams,
          },
        });
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }

  return useQuery(identifiers, api, {
    ...defaultReactQueryParams,
    ...reactQueryParams,
  });
}

type UseIntercomFAQArticlesProps = ListQuery & {
  parent_id?: string;
  search?: string;
  state?: string;
};
export function useIntercomFAQArticles<R = IntercomArticle>(
  { page, ...restListQueryParams }: UseIntercomFAQArticlesProps,
  reactQueryParams: Partial<QueryParams> = {},
) {
  const identifiers = ['intercomFAQArticles'];

  if (page) {
    identifiers.push(`${page}`);
  }
  if (restListQueryParams.parent_id) {
    identifiers.push(restListQueryParams.parent_id);
  }
  if (restListQueryParams.search) {
    identifiers.push(restListQueryParams.search);
  }
  if (restListQueryParams.state) {
    identifiers.push(restListQueryParams.state);
  }

  async function api({
    signal,
  }: QueryFunctionContext): Promise<AxiosResponse<ListResult<R>>> {
    return new Promise(async (resolve, reject) => {
      try {
        const result: AxiosResponse<ListResult<R>> = await request.request({
          url: `/api/intercom-auth0/faq-articles/`,
          signal,
          params: {
            ...restListQueryParams,
          },
        });
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }

  return useQuery(identifiers, api, {
    ...defaultReactQueryParams,
    ...reactQueryParams,
  });
}
