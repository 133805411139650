import React, {
  SyntheticEvent,
  useRef,
  useState,
  MutableRefObject,
  useMemo,
} from 'react';
import { ReactRenderElement } from '../types/types';
import { isSimilarObject } from '../utils/object';

type Props = {
  setRef?: MutableRefObject<HTMLFormElement | null>;
  children?: ReactRenderElement;
  onSubmit?: (evt: SyntheticEvent) => void;
};

type ChangedState<FS> = Partial<FS>;
export type FormStateRet<FS> = {
  formRef: MutableRefObject<HTMLFormElement | null>;
  formState: FS;
  changedState: ChangedState<FS>;
  handleChange: (evt: EventTarget | SyntheticEvent) => void;
  handleSubmit: (
    callback: (formState: FS, changedState: ChangedState<FS>) => void,
  ) => (evt: SyntheticEvent) => void;
  resetState: () => void;
  setFormState: (state: FS | (() => FS)) => void;
  submit: () => void;
  updateState: (callback: UpdateStateCallback<FS>) => void;
  error: FormStateError;
  hasChanged: boolean;
};

type EventTarget = {
  target: {
    name: string;
    value: any;
  };
};

type FormStateError = {
  message?: string;
  [key: string]: any;
} | null;

type UpdateStateCallback<FS = any> = (state: FS) => FS;

type FormOptions = {
  onStateUpdated?: () => void;
};

export function useForm<FS>(
  initialValue: FS | (() => FS) = {} as FS,
  options?: FormOptions,
): FormStateRet<FS> {
  const formRef = useRef<HTMLFormElement | null>(null);
  const [originalState, setOriginalState] = useState<FS>(initialValue);
  const [formState, setFormState] = useState<FS>(initialValue);
  const [changedState, setChangedState] = useState<ChangedState<FS>>(
    {} as ChangedState<FS>,
  );
  const [error, setError] = useState<FormStateError>(null);

  const hasChanged = useMemo(
    () => !isSimilarObject(originalState, formState),
    [originalState, formState],
  );

  function handleChange(evt: EventTarget | SyntheticEvent) {
    const input = evt.target as HTMLInputElement;
    const name = input.name;
    const value = input.value;

    if (!name) throw new Error('Missing name on input field');

    setChangedState((state) => ({ ...state, [name]: value }));
    setFormState((state) => ({ ...state, [name]: value }));
    options?.onStateUpdated?.();
  }

  function updateState(callback: UpdateStateCallback<FS>) {
    setChangedState((state) => callback(state as FS));
    setFormState((state) => callback(state as FS));
    options?.onStateUpdated?.();
  }

  function handleSubmit(
    callback: (formState: FS, changedState: ChangedState<FS>) => void,
  ): (evt: SyntheticEvent) => void {
    return function (evt: SyntheticEvent) {
      evt.preventDefault();
      try {
        setError(null);
        callback?.(formState, changedState);
      } catch (e) {
        setError({ message: (e as Error).message });
      }
    };
  }

  function resetState() {
    setFormState(initialValue);
    setOriginalState(initialValue);
    setChangedState({});
    options?.onStateUpdated?.();
  }

  function submit() {
    formRef.current?.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true }),
    );
  }

  return {
    formRef,
    formState,
    hasChanged,
    changedState,
    handleChange,
    handleSubmit,
    resetState,
    setFormState,
    submit,
    updateState,
    error,
  };
}

const BasicForm = ({ setRef, children, onSubmit }: Props) => {
  return (
    <form
      ref={setRef}
      autoComplete='off'
      autoSave='off'
      autoCapitalize='off'
      autoCorrect='off'
      style={{ width: '100%' }}
      onSubmit={onSubmit}
    >
      <fieldset
        style={{
          border: 0,
          margin: 0,
          padding: 0,

          display: 'block',
        }}
      >
        {children}
      </fieldset>
    </form>
  );
};
export default BasicForm;
