import unescape from 'lodash/unescape';
import { IterableObject } from '../types/types';
import { sortBy } from './array';

/**
 * Need to convert the type response especially the ID, to avoid mismatching
 * when looking up
 */
export const mutateIdToString = (
  array: IterableObject[] = [],
  fields = ['id'],
) => {
  array.forEach((item) => {
    fields.forEach((key) => {
      if (key in item) {
        item[key] = `${item[key]}`;
      }
    });
  });
};

export const mutateFieldEscapeString = (
  array: IterableObject[] = [],
  fields = ['id'],
) => {
  array.forEach((item) => {
    fields.forEach((key) => {
      if (key in item) {
        item[key] = unescape(item[key]);
      }
    });
  });
};

export const isNotNullAndUndefined = (value: any) => {
  return typeof value !== 'undefined' && value !== null;
};

export const isIterableObject = (value: any) => {
  return typeof value === 'object' && value !== null;
};

export function isSimilarObject(obj1: any, obj2: any): boolean {
  if (
    typeof obj1 !== 'undefined' &&
    typeof obj2 !== 'undefined' &&
    obj1 !== null &&
    obj2 !== null
  ) {
    const keys1: string[] = sortBy(
      Object.keys(obj1),
      null,
      true,
      'asc',
    ) as string[];
    const keys2: string[] = sortBy(
      Object.keys(obj2),
      null,
      true,
      'asc',
    ) as string[];
    let uniqKeys = Array.from(new Set([...keys1, ...keys2]));

    if (
      keys1.length !== keys2.length ||
      uniqKeys.length !== keys1.length ||
      uniqKeys.length !== keys2.length
    ) {
      return false;
    }

    for (let i = 0; i < uniqKeys.length; i++) {
      let key = uniqKeys[i];
      let value1 = obj1[key];
      let value2 = obj2[key];

      // check of both the type is not the same, then it's not the same object
      if (typeof value1 !== typeof value2) return false;

      // here both value1 and value2 are same type
      if (isIterableObject(value1)) {
        return isSimilarObject(value1, value2);
      } else if (value1 !== value2) {
        return false;
      }
    }

    // it reaches here, it means both object are the similar (structure)
    return true;
  }

  if (obj1 !== obj2) return false;

  return true;
}
