import { Box, SxProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useAppProvider } from '../../providers/app/app';
import { PaletteTypeButtonProps } from '../../types/app';
import { applyOpacityOnColor } from '../../utils/color';

type Elevation = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
type Props = PropsWithChildren &
  PaletteTypeButtonProps & {
    sx?: SxProps;
    borderRadius?: number;
    wrapperSx?: SxProps;
    elevation?: Elevation;
  };

const M3SurfaceContainer = ({
  sx,
  borderRadius,
  wrapperSx,
  elevation,
  palette: paletteKey = 'main',
  children,
}: Props) => {
  const {
    palettes: { [paletteKey]: palette },
  } = useAppProvider();

  return (
    <Box
      sx={{
        borderRadius,
        background: `var(--md-sys-color-on-primary-light)`,
        ...wrapperSx,
      }}
    >
      <Box
        sx={{
          borderRadius,
          background: elevation
            ? applyOpacityOnColor(
                palette['md.ref.palette.primary40'],
                getSurfaceOpacityByElevation(elevation),
              )
            : undefined,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default M3SurfaceContainer;

export function getSurfaceOpacityByElevation(elevation?: Elevation): number {
  if (elevation === 0) {
    return 0.02;
  } else if (elevation === 1) {
    return 0.05;
  } else if (elevation === 2) {
    return 0.08;
  } else if (elevation === 3) {
    return 0.11;
  } else if (elevation === 4) {
    return 0.12;
  } else if (elevation === 5) {
    return 0.14;
  } else if (elevation === 6) {
    return 0.16;
  } else if (elevation === 7) {
    return 0.17;
  } else if (elevation === 8) {
    return 0.18;
  } else if (elevation === 9) {
    return 0.19;
  } else {
    return 1;
  }
}
