import React, { FC, ReactElement, ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

type Props = {
  flex?: boolean;
  children?: ReactNode | ReactElement | ReactElement[] | ReactNode[];
  sx?: SxProps;
};

const MainContainer: FC<Props> = ({ flex = true, children, sx }) => {
  return (
    <Box
      flex={1}
      sx={{
        p: 4,
        pt: 3,
        flex: 1,
        maxWidth: 1068,
        position: 'relative',
        width: flex ? 0 : null,
        ...sx,
      }}
      className='main-container'
    >
      {children}
    </Box>
  );
};

export default MainContainer;
