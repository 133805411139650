export type ListResult<T, M = any> = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: T[];
  meta?: M;
};

export type BasicResult<T> = {
  count: number;
  data: T[];
};

export const defaultListResult = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

export const defaultBasicResult = {
  count: 0,
  data: [],
};
