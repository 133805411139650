import {
  AppBar,
  Box,
  Stack,
  Toolbar,
  Link,
  SxProps,
  useMediaQuery,
  Button,
} from '@mui/material';
import React, { CSSProperties, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { MenuItem } from '../../types/types';
import MainContainer from '../MainContainer';

type Props = {};
const toolbarHeight = 80;

const FAQCareersHeader = (props: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const matches1200 = useMediaQuery(`(max-width: 1200px)`);
  const matches991 = useMediaQuery(`(max-width: 991px)`);

  const linkSx: SxProps = {
    color: matches991 ? '#000' : '#fff',
    fontWeight: matches991 ? 'bold' : undefined,
    height: matches991 ? 48 : 40,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    textDecoration: 'none',
    fontFamily: `'Open Sans', Lato, Helvetica, Sans-serif`,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
    },
  };
  const linkStyle: CSSProperties = {
    margin: '0 2px',
    padding: '8px 12px',
    borderRadius: '4px',
  };

  const menuLinks: MenuItem[] = [
    {
      id: 'home',
      name: 'Home',
      path: 'https://careers.go2.io',
    },
    {
      id: 'about',
      name: 'About',
      path: 'https://careers.go2.io/about-us',
    },
    {
      id: 'how-we-hire',
      name: 'How We Hire',
      path: 'https://careers.go2.io/how-we-hire',
    },
    {
      id: 'faq',
      name: 'FAQ',
      path: `${window.location.origin}/frequently-asked-questions/collection/${process.env.REACT_APP_FAQ_COLLECTION_ID}`,
      navigate: false,
    },
  ];

  const socialLinkStyle = {
    transform: `scale(${matches991 ? 0.83 : 1})`,
  };
  const socialLinks: MenuItem[] = [
    {
      id: 'fb',
      name: 'Facebook',
      path: 'https://www.facebook.com/go2recruitment',
      icon: (
        <svg
          width={18}
          height={18}
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          style={socialLinkStyle}
        >
          <path
            d='M22.67 0H1.33A1.34 1.34 0 0 0 0 1.33v21.34A1.34 1.34 0 0 0 1.33 24h11.49v-9.28H9.7v-3.63h3.12V8.42c0-3.1 1.89-4.79 4.67-4.79.93 0 1.86 0 2.79.14V7h-1.91c-1.51 0-1.8.72-1.8 1.77v2.31h3.6l-.47 3.63h-3.13V24h6.1A1.34 1.34 0 0 0 24 22.67V1.33A1.34 1.34 0 0 0 22.67 0Z'
            fill='currentColor'
          />
        </svg>
      ),
    },
    {
      id: 'twitter',
      name: 'Twitter',
      path: 'https://www.linkedin.com/company/go2-staff/',
      icon: (
        <svg
          width={18}
          height={18}
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          style={socialLinkStyle}
        >
          <path
            d='M22.2 0H1.8C.8 0 0 .8 0 1.7v20.5c0 .9.8 1.7 1.8 1.7h20.4c1 0 1.8-.8 1.8-1.7V1.7c0-.9-.8-1.7-1.8-1.7ZM7.1 20.4H3.6V9h3.5v11.4Zm-1.7-13c-1.1 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1 1.1 0 2.1.9 2.1 2.1 0 1.2-1 2.1-2.1 2.1Zm15.1 12.9H17v-5.6c0-1.3 0-3.1-1.9-3.1S13 13.1 13 14.5v5.7H9.5V9h3.3v1.5h.1c.5-.9 1.7-1.9 3.4-1.9 3.6 0 4.3 2.4 4.3 5.5v6.2h-.1Z'
            fill='currentColor'
          />
        </svg>
      ),
    },
  ];

  const renderLinks = () => {
    if (!isMenuOpen && matches991) return null;

    return (
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Stack
          gap={0}
          direction={matches991 ? 'column' : 'row'}
          flexWrap='nowrap'
          justifyContent='flex-end'
          sx={
            matches991
              ? {
                  top: 10,
                  right: 0,
                  borderRadius: 1,
                  position: 'absolute',
                  background: '#fff',
                  boxShadow: `rgb(0 0 0 / 12%) 0px 2px 8px`,
                  pt: 1,
                  pb: 2,
                  width: 312,
                }
              : null
          }
        >
          {menuLinks.map((menu) => {
            return (
              <Link
                key={menu.id}
                href={menu.path}
                sx={linkSx}
                style={linkStyle}
              >
                {menu.name}
              </Link>
            );
          })}
          <div style={{ width: 12 }}></div>
          {socialLinks.map((menu) => {
            return (
              <Link
                key={menu.id}
                href={menu.path}
                sx={linkSx}
                style={{
                  ...linkStyle,
                  width: 40,
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginLeft: 2,
                  ...(matches991
                    ? {
                        width: '100%',
                        height: 39,
                        marginLeft: 0,
                      }
                    : null),
                }}
                target='_blank'
              >
                {menu.icon}
              </Link>
            );
          })}
          <Link
            href='https://careers.go2.io/o/general-roles'
            sx={{ ...linkSx }}
            style={{
              ...linkStyle,
              marginLeft: 14,
              marginRight: 0,
              fontWeight: 300,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: '#fff',
              ...(matches991
                ? {
                    height: 40,
                    borderColor: '#000',
                    maxWidth: 130,
                    margin: '0 auto',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 8,
                  }
                : null),
            }}
          >
            Join Our Team
          </Link>
        </Stack>
      </Box>
    );
  };

  const renderMenuButton = () => {
    if (!matches991) return null;

    return (
      <Box
        sx={{
          width: 42,
        }}
      >
        <Button
          disableRipple
          sx={{
            ...linkSx,
            width: 42,
            height: 40,
            maxWidth: 42,
            minWidth: null,
          }}
          style={{
            width: 42,
            maxWidth: 42,
            minWidth: 42,
          }}
          onClick={() => setIsMenuOpen((state) => !state)}
        >
          {isMenuOpen ? (
            <CloseIcon sx={{ fontSize: 27, color: '#fff' }} />
          ) : (
            <MenuIcon sx={{ color: '#fff' }} />
          )}
        </Button>
      </Box>
    );
  };

  return (
    <>
      <AppBar
        sx={{
          color: '#fff',
          background: '#3d464a',
        }}
      >
        <MainContainer
          flex
          sx={{
            p: 0,
            pt: 0,
            width: '100%',
            maxWidth: matches991 ? 704 : matches1200 ? 920 : 1152,
            margin: '0 auto',
          }}
        >
          <Toolbar
            sx={{
              height: toolbarHeight,
            }}
            style={{
              paddingLeft: 8,
              paddingRight: 0,
              justifyContent: 'space-between',
            }}
          >
            <Link
              href='https://careers.go2.io/'
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box
                component='img'
                src='https://careers.recruiteecdn.com/image/upload/q_auto,f_auto,w_400,c_limit/production/images/nGM/Ckxh_u0iRAYn.png'
                sx={{
                  height: 59,
                }}
              />
            </Link>
            <Box>
              {renderMenuButton()}
              {renderLinks()}
            </Box>
          </Toolbar>
        </MainContainer>
      </AppBar>
      <Toolbar sx={{ height: toolbarHeight }} />
    </>
  );
};

export default FAQCareersHeader;
