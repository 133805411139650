import React from 'react';
import { Box, Link } from '@mui/material';

import { ReactComponent as IntercomFacebookIcon } from '../../assets/intercom/facebook.svg';

type IntercomFooterProps = {
  withGo2HelpLink?: boolean;
  go2HelpText?: string;
  go2HelpLink?: string;
};

const IntercomFooter = ({
  withGo2HelpLink = true,
  go2HelpText = 'Go2 Help Center',
  go2HelpLink = 'https://support.go2.io/en/',
}: IntercomFooterProps) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      sx={{
        pt: 7,
        pb: 7,
        background: 'var(--md-ref-palette-primary99)',
      }}
    >
      {withGo2HelpLink && (
        <Link
          underline='none'
          sx={{
            mb: 5,
            fontSize: 18,
            opacity: 0.5,
            color: 'var(--md-sys-color-on-surface-light)',
          }}
          target='_blank'
          href={go2HelpLink}
        >
          {go2HelpText}
        </Link>
      )}
      <Link
        underline='none'
        target='_blank'
        href='https://www.facebook.com/go2recruitment/'
      >
        <IntercomFacebookIcon
          style={{
            width: 17,
            height: 17,
            opacity: 0.5,
            color: 'var(--md-sys-color-on-surface-light)',
          }}
        />
      </Link>
    </Box>
  );
};

export default IntercomFooter;
