import React, { createContext, useContext, useState } from 'react';

import { SodEodButtons, sodEodButtons } from '.';
import { IterableObject, ReactRenderElement } from '../../types/types';
import mainPalette from '../../assets/palettes/main';
import { extractTokenToPalette } from '../../utils/color';
import { getFromLocalStorage } from '../../utils/localstorage';

type AppProviderProps = {
  children?: ReactRenderElement;
};
type ExtractedPalette = IterableObject<string>;

/**
 * State that we can mutate
 */
type AppInitialState = {
  drawerWidth: number;
  toolbarHeight: number;
  subToolbarHeight: number;
  sodEodButtons: SodEodButtons;
  isDarkMode: boolean;
  /**
   * List all the actions you want to listen for changes.
   * This is useful when you wan to listen for global action that is outside
   * of current screen
   */
  actionKeyCounter: {
    pto_request_submission: number;
    user_save_settings: number;
    sod_eod_submission: number;
    fetch_all_notification_count: number;
  };
  /**
   * Container for palette
   */
  paletteSource: string;
  palettes: IterableObject<ExtractedPalette> & {
    main: ExtractedPalette;
  };
};
/**
 * List here should match the "actionKeyCounter" object
 */
type actionKeysCounter =
  | 'pto_request_submission'
  | 'user_save_settings'
  | 'sod_eod_submission'
  | 'fetch_all_notification_count';
/**
 * Reducers that mutate the state
 */
type AppReducers = {
  updateActionKeyCounter: (key: actionKeysCounter) => void;
  getActionKeyCounter: (key: actionKeysCounter) => number;
  updatePalette: (palette?: string | null) => void;
};
/**
 * Single store
 */
type AppStore = AppInitialState & AppReducers;
let localUserColorScheme = getFromLocalStorage('user_color_scheme');
/**
 * Initial state / store
 */
const initialStore: AppStore = {
  drawerWidth: 300,
  toolbarHeight: 64,
  subToolbarHeight: 64,
  sodEodButtons,
  isDarkMode: false,
  actionKeyCounter: {
    pto_request_submission: 0,
    user_save_settings: 0,
    sod_eod_submission: 0,
    fetch_all_notification_count: 0,
  },
  paletteSource: localUserColorScheme || mainPalette,
  palettes: {
    main: extractTokenToPalette(localUserColorScheme || mainPalette),
  },
  updateActionKeyCounter: () => {
    throw new Error('Implementation required');
  },
  getActionKeyCounter: () => {
    throw new Error('Implementation required');
  },
  updatePalette: (palette?: string | null) => {
    throw new Error('Implementation required');
  },
};
/**
 * Context Instance
 */
const AppContext = createContext<AppStore>(initialStore);

export function useAppProvider(): AppStore {
  return useContext(AppContext);
}

export function AppProvider({ children }: AppProviderProps) {
  const [state, setState] = useState<AppStore>(initialStore);

  /**
   * Define all the handlers here how you want to mutate the state
   */
  function updateActionKeyCounter(key: actionKeysCounter) {
    setState((state) => ({
      ...state,
      actionKeyCounter: {
        ...state.actionKeyCounter,
        [key]: (state.actionKeyCounter[key] ?? 0) + 1,
      },
    }));
  }

  function getActionKeyCounter(key: actionKeysCounter): number {
    return state.actionKeyCounter[key] ?? 0;
  }

  function updatePalette(palette?: string | null) {
    let source = palette || mainPalette;
    setState((state) => ({
      ...state,
      paletteSource: source,
      palettes: {
        ...state.palettes,
        main: extractTokenToPalette(source),
      },
    }));
  }

  /**
   * Define all side effects here...
   */

  return (
    <AppContext.Provider
      value={{
        ...state,
        updateActionKeyCounter,
        getActionKeyCounter,
        updatePalette,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
