import React from 'react';
import { Global, css } from '@emotion/react';

import { useAppProvider } from '../../providers/app/app';

type Props = {};

/**
 * This palette was generated with m3 theme builder:
 * https://m3.material.io/theme-builder#/custom
 */
const M3ThemePalette = (props: Props) => {
  const { paletteSource, drawerWidth } = useAppProvider();

  return (
    <Global
      key={paletteSource}
      styles={css`
        ${paletteSource}

        body, html {
          background: var(--md-sys-color-on-primary-light);
          font-family: 'Roboto Flex', 'Lato', 'Helvetica Neue', sans-serif;
        }

        :root {
          --app-drawer-width: ${drawerWidth}px;
        }
      `}
    />
  );
};

export default M3ThemePalette;
