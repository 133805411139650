import React from 'react';
import { AvatarGroup, Box, Paper, Skeleton } from '@mui/material';

type Props = {};

const IntercomCollectionSkeleton = (props: Props) => {
  return (
    <Paper className='collection__paper'>
      <Skeleton variant='text' sx={{ fontSize: 18, maxWidth: 630 }} />
      <Skeleton
        variant='text'
        sx={{ fontSize: 16 }}
        style={{
          maxWidth: 630,
          margin: '5px 0 11px',
        }}
      />
      <Box display='flex' justifyContent='flex-start' alignItems='flex-start'>
        <AvatarGroup max={2} style={{ paddingRight: 10 }}>
          <Skeleton variant='circular' width={36} height={36} />
        </AvatarGroup>
        <Box flex={1} sx={{ maxWidth: 200 }}>
          <Skeleton variant='text' sx={{ fontSize: 13 }} />
          <Skeleton variant='text' sx={{ fontSize: 13 }} />
        </Box>
      </Box>
    </Paper>
  );
};

export default IntercomCollectionSkeleton;
