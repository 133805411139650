import React, { useEffect, useLayoutEffect } from 'react';
import { Box } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import DocumentTitle from '../components/DocumentTitle';

import FAQCareersHeader from '../components/FAQCareers/FAQCareersHeader';
import IntercomHeader from '../components/Intercom/IntercomHeader';
import IntercomFooter from '../components/Intercom/IntercomFooter';
import IntercomCollections from '../components/Intercom/IntercomCollections';
import IntercomSearchResults from '../components/Intercom/IntercomSearchResults';
import IntercomCollectionDetail from '../components/Intercom/IntercomCollectionDetail';
import MainContainer from '../components/MainContainer';

import { useIntercomAllData } from '../providers/intercom';
import { useIntercomProvider } from '../providers/intercom/intercom';
import {
  useIntercomFAQArticles,
  useIntercomFAQSections,
  useIntercomFAQArticlesInCollection,
  useIntercomFAQAdmins,
  useIntercomFAQCollections,
} from '../hooks/services/intercom';

type Props = {};

const FAQCareers = (props: Props) => {
  const { search, setAllData } = useIntercomProvider();
  const allData = useIntercomAllData({
    useIntercomAdminsHook: useIntercomFAQAdmins,
    useIntercomCollectionsHook: useIntercomFAQCollections,
  });
  const basePath = '/frequently-asked-questions';

  useLayoutEffect(() => {
    document.body.classList.add('faq-careers-page');

    return () => {
      document.body.classList.remove('faq-careers-page');
    };
  }, []);

  useEffect(() => {
    if (allData.ok) {
      setAllData(allData.data);
      allData.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData.ok]);

  return (
    <>
      <DocumentTitle withAppTitle={false} title='FAQ' />
      <Box flex={1} sx={{ position: 'relative', zIndex: 1 }}>
        <FAQCareersHeader />
        <Box className='intercom-page'>
          <IntercomHeader
            withTitle={false}
            withGo2HelpLink={false}
            basePath={basePath}
          />
          <Box>
            <MainContainer
              flex={false}
              sx={{
                maxWidth: 980,
                minHeight: 'calc(100vh - 365px)',
                margin: 'auto',
              }}
            >
              <Routes>
                <Route
                  path='/collection/:collectionId'
                  element={
                    <IntercomCollectionDetail
                      basePath={basePath}
                      useIntercomSectionsHook={useIntercomFAQSections}
                      useIntercomArticlesInCollectionHook={
                        useIntercomFAQArticlesInCollection
                      }
                    />
                  }
                />
                <Route
                  path='/'
                  element={
                    search ? (
                      <IntercomSearchResults
                        useIntercomArticlesHook={useIntercomFAQArticles}
                      />
                    ) : (
                      <IntercomCollections basePath={basePath} />
                    )
                  }
                />
              </Routes>
            </MainContainer>
          </Box>
          <IntercomFooter withGo2HelpLink={false} />
        </Box>
      </Box>
    </>
  );
};

export default FAQCareers;
