export function sortBy<T>(
  items: T[] = [],
  key?: string | null,
  toUpperCase?: boolean,
  order: 'asc' | 'desc' | 'ASC' | 'DESC' = 'asc',
  type?: 'date_string' | 'date' | 'number',
): T[] {
  order = order.toUpperCase() === 'ASC' ? 'ASC' : 'DESC';
  return items
    .sort((a, b) => {
      let aValue: any = a;
      let bValue: any = b;
      if (typeof aValue === 'object' && key) {
        const aV: any = aValue[key];
        const bV: any = bValue[key];
        aValue = typeof aV === 'string' && toUpperCase ? aV.toUpperCase() : aV;
        bValue = typeof bV === 'string' && toUpperCase ? bV.toUpperCase() : bV;
      } else {
        aValue =
          typeof aValue === 'string' && toUpperCase
            ? aValue.toUpperCase()
            : aValue;
        bValue =
          typeof bValue === 'string' && toUpperCase
            ? bValue.toUpperCase()
            : bValue;
      }
      if (type === 'date_string' || type === 'date') {
        aValue = new Date(aValue).getTime();
        bValue = new Date(bValue).getTime();
      } else if (type === 'number') {
        const nValueA = Number(aValue);
        const nValueB = Number(bValue);
        aValue = isNaN(nValueA) ? aValue : nValueA;
        bValue = isNaN(nValueB) ? bValue : nValueB;
      }
      if (order === 'ASC') {
        if (aValue > bValue) {
          return 1;
        }
        if (aValue < bValue) {
          return -1;
        }
        return 0;
      }
      if (aValue > bValue) {
        return -1;
      }
      if (aValue < bValue) {
        return 1;
      }
      return 0;
    })
    .slice(0);
}
