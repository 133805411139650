import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import 'moment-timezone';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { ProviderWrapper } from './App';
import FAQCareers from './pages/FAQCareers';

import M3ThemePalette from './components/M3/M3ThemePalette';

import './styles';
import reportWebVitals from './reportWebVitals';
import theme from './assets/theme';
import { ReactRenderElement } from './types/types';
import { AppProvider } from './providers/app/app';
import { IntercomProvider } from './providers/intercom/intercom';

/**
 * Initialize the sentry the start of everything
 */
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN!,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['*'],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: +process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE!,
  });
}

const queryClient = new QueryClient();

const RenderRoutes = () => {
  let content: ReactRenderElement = renderAllRoutes();

  return (
    <>
      <M3ThemePalette />
      <Routes>{content}</Routes>
    </>
  );
};

const renderAllRoutes = () => {
  return (
    <>
      <Route path='/frequently-asked-questions/*' element={<FAQCareers />} />
      <Route path='*' element={<Navigate to='/frequently-asked-questions' />} />
    </>
  );
};

function RootDataProviders({
  children,
}: {
  children: ReactRenderElement;
}): ReactElement {
  const providers: ProviderWrapper[] = [AppProvider, IntercomProvider];

  return providers.reduceRight(
    (children, Provider: ProviderWrapper) => <Provider>{children}</Provider>,
    children,
  ) as ReactElement;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <RootDataProviders>
            <RenderRoutes />
          </RootDataProviders>
        </BrowserRouter>
      </QueryClientProvider>
    </StyledEngineProvider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
