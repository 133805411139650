import { useState } from 'react';
import { ListResult } from '../../types/response';

export type UsePaginationRet = {
  params: {
    page: number;
    total: number;
    offset: number;
    limit?: number;
  };
  setPage: (page: number) => void;
};

type ListResultLimit = {
  limit?: number;
};

export function usePagination<T>({
  count: listResultCount,
  limit = 10,
}: ListResultLimit & ListResult<T>): UsePaginationRet {
  // current page
  let [page, setPage] = useState(1);
  // total pages
  const total = Math.ceil(listResultCount / limit) || 1;
  // start of the offset
  const offset = (page - 1) * limit;

  return {
    params: {
      page,
      total,
      offset,
      limit,
    },
    setPage,
  };
}
