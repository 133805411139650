import React from 'react';
import clsx from 'clsx';
import { Card, CardProps, styled } from '@mui/material';

export const M3CardBase = styled(Card)`
  transition: 50ms;
  border-radius: 12px;

  &:not(.no-hover) {
    &.focused,
    &:hover {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }
  }
`;

type M3CardProps = CardProps & {
  focused?: boolean;
  noHover?: boolean;
};
export const M3Card = ({
  className,
  focused,
  noHover,
  ...restProps
}: M3CardProps) => {
  return (
    <M3CardBase
      {...restProps}
      className={clsx(className, {
        focused,
        'no-hover': noHover,
      })}
    />
  );
};
