import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

type Item = {
  id: string;
  path: string;
  label?: string | null;
  navigate?: boolean | null;
};
type Props = {
  items: Item[];
};

const IntercomBreadcrumbs = ({ items }: Props) => {
  return (
    <Breadcrumbs
      sx={{
        pt: 1,
        pb: 1,
      }}
      separator={
        <NavigateNextIcon
          fontSize='medium'
          sx={{
            color: '#c3c9cd',
            position: 'relative',
            top: 1,
            ml: -0.5,
            mr: -0.5,
          }}
        />
      }
    >
      {items.map((item, index) => {
        if (index === items.length - 1) {
          return (
            <Typography key={item.id} fontSize={14} color='#8f919d'>
              {item.label}
            </Typography>
          );
        }
        return (
          <Link
            key={item.id}
            to={item.path}
            style={{
              fontSize: 14,
              color: '#4f5e6b',
              textDecoration: 'none',
            }}
          >
            {item.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default IntercomBreadcrumbs;
