import React, { createContext, useContext, useEffect, useState } from 'react';

import './Intercom.css';
import * as intercom from '../../services/intercom';
import { IntercomAdmin, IntercomCollection } from '../../types/intercom';
import { IntercomAllDataProps } from '.';
import { ReactRenderElement } from '../../types/types';

type IntercomProviderProps = {
  children?: ReactRenderElement;
};
/**
 * State that we can mutate
 */
type IntercomInitialState = {
  search: string;
  collections: IntercomCollection[];
  admins: IntercomAdmin[];
  supportUnreadCount: number;
  intercomLoaded: boolean;
};
/**
 * Reducers that mutate the state
 */
type IntercomReducers = {
  setSearch: (search: string) => void;
  setAllData: (data: IntercomAllDataProps) => void;
};
/**
 * Single store
 */
type IntercomStore = IntercomInitialState & IntercomReducers;
/**
 * Initial state / store
 */
const initialStore: IntercomStore = {
  search:
    new URLSearchParams(window.location.search.replace('?', '')).get(
      'search',
    ) ?? '',
  collections: [],
  admins: [],
  supportUnreadCount: 0,
  intercomLoaded: false,
  setSearch: (search: string) => {
    throw new Error('Implementation required');
  },
  setAllData: (data: IntercomAllDataProps) => {
    throw new Error('Implementation required');
  },
};
/**
 * Context Instance
 */
const IntercomContext = createContext<IntercomStore>(initialStore);

export function useIntercomProvider(): IntercomStore {
  return useContext(IntercomContext);
}

export function IntercomProvider({ children }: IntercomProviderProps) {
  const [state, setState] = useState<IntercomStore>(initialStore);

  /**
   * Define all the handlers here how you want to mutate the state
   */
  function setSearch(search: string = '') {
    setState((state) => ({ ...state, search }));
  }

  function setAllData(data: IntercomAllDataProps) {
    setState((state) => ({ ...state, ...data }));
  }

  /**
   * Define all side effects here...
   */
  useEffect(() => {
    intercom.init();
  }, []);

  return (
    <IntercomContext.Provider
      value={{
        ...state,
        setSearch,
        setAllData,
      }}
    >
      {children}
    </IntercomContext.Provider>
  );
}
